import UploadAdapter from './upload_adapter';

function CustomUploadAdapterPlugin( editor ) {
    editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
        // Configure the URL to the upload script in your back-end here!
        return new UploadAdapter( loader, editor );
    };
}

import { Controller } from "stimulus"

export default class extends Controller {
 connect(){
  ClassicEditor
      .create( this.element,
          {
            //...
            toolbar: {
              items: [
                'heading',
                '|',
                'bold',
                'italic',
                'underline',
                'fontColor',
                'fontSize',
                'fontBackgroundColor',
                'subscript',
                'superscript',
                'alignment',
                'link',
                'bulletedList',
                'numberedList',
                '|',
                'outdent',
                'indent',
                '|',
                'imageUpload',
                'blockQuote',
                'insertTable',
                'mediaEmbed',
                'undo',
                'redo',
                'htmlEmbed'
              ]
            },
            language: 'zh',
            image: {
              toolbar: [
                'imageTextAlternative',
                'imageStyle:full',
                'imageStyle:side',
                'linkImage'
              ]
            },
            table: {
              contentToolbar: [
                'tableColumn',
                'tableRow',
                'mergeTableCells',
                'tableCellProperties',
                'tableProperties'
              ]
            },
            licenseKey: '',
            extraPlugins: [ 
              CustomUploadAdapterPlugin,
             ],
            allowedContent: true,
            customUpload: {
              directUploadUrl: this.element.dataset.directUploadUrl,
              blobUrlTemplate: this.element.dataset.blobUrlTemplate
            }
          }
      )
      .then( editor => {

        console.log( editor );
      } )
      .catch( error => {
        console.error( error );
      });
   }
}