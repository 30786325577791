// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

//20210424.cot: nested form
import { Application } from "stimulus"
import NestedForm from "stimulus-rails-nested-form"
import ScrollTo from "stimulus-scroll-to"
import Carousel from "stimulus-carousel"
import AnimatedNumber from "stimulus-animated-number"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import Sortable from "stimulus-sortable"
import Lightbox from "stimulus-lightbox"
import Pickr from "@simonwep/pickr"
import Autosave from "stimulus-rails-autosave"
import StimulusSlimSelect from "stimulus-slimselect"

const application = Application.start()
const context = require.context("controllers", true, /.js$/);
application.register("nested-form", NestedForm)
application.register("scroll-to", ScrollTo)
application.register("carousel", Carousel)
application.register("animated-number", AnimatedNumber)
application.load(definitionsFromContext(context))
application.register("sortable", Sortable)
application.register("lightbox", Lightbox)
application.register("autosave", Autosave)
application.register('slimselect', StimulusSlimSelect)

// jquery
import $ from 'jquery'
global.$ = $
global.jQuery = $

//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

//20210319.cot: tailwindcss
import "stylesheets/application"
//import "stylesheets/admin"
//import "stylesheets/dropdown"

//require("trix")
//require("@rails/actiontext")

import 'application/sweet_alert2';
import 'application/ckeditor';

import 'swiper/swiper-bundle.min.css';
import "lightgallery.js/dist/css/lightgallery.min.css";

//20211219.cot: client side validations
import '@client-side-validations/client-side-validations/src'

//20210718.cot: the second nested-form
//https://stevepolito.design/blog/create-a-nested-form-in-rails-from-scratch/
require('../application/nested-forms/addFields')
require('../application/nested-forms/removeFields')